<template>
  <div
    class="archive relative"
  >
    <div class="header flex justify-between items-center pr-4 pl-6 pt-5 pb-2 border-b border-divider">
      <div class="text-xl font-bold whitespace-no-wrap font-SourceSansPro flex flex-col archive__header">
        {{ $t('archive.title') }}
        <span class="archive__header-small">
          {{ $t('archive.subtitle') }}: {{ unit.name }}
        </span>
      </div>
      <BackIcon
        class="cursor-pointer tool-icon"
        @click.native="$emit('close')"
      />
    </div>
    <div class="archive-options relative">
      <ElInput
        v-model="search"
        :placeholder="$t('archive.search')"/>

      <div class="archive-options__option" @click="openViewEdit">

        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none">
          <path d="M1.35482 6.71467C1.30881 6.57639 1.30881 6.42694 1.35482 6.28867C2.27948 3.50667 4.90415 1.5 7.99749 1.5C11.0895 1.5 13.7128 3.50467 14.6395 6.28533C14.6862 6.42333 14.6862 6.57267 14.6395 6.71133C13.7155 9.49333 11.0908 11.5 7.99749 11.5C4.90549 11.5 2.28148 9.49533 1.35482 6.71467Z" stroke="#3A7CCA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M9.99749 6.5C9.99749 7.03043 9.78677 7.53914 9.4117 7.91421C9.03663 8.28929 8.52792 8.5 7.99749 8.5C7.46705 8.5 6.95834 8.28929 6.58327 7.91421C6.2082 7.53914 5.99748 7.03043 5.99748 6.5C5.99748 5.96957 6.2082 5.46086 6.58327 5.08579C6.95834 4.71071 7.46705 4.5 7.99749 4.5C8.52792 4.5 9.03663 4.71071 9.4117 5.08579C9.78677 5.46086 9.99749 5.96957 9.99749 6.5Z" stroke="#3A7CCA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

        <span class="text-base m-w curiousBlue">{{ $t('archive.view') }}</span>

        <!-- Отображение -->
        <div
          v-click-outside="closeViewEdit"
          v-if="isOpenViewEdit"
          class="absolute z-10 flex flex-col items-center justify-between gap-1 h-[28px]
            w-48 bg-white shadow-lg rounded py-2 border
            border-lightGrey-300 table-show-type">
          <div class="flex items-center state-radio-group mb-2">
            <ElRadioGroup v-model="stateStyle" size="small">
              <ElRadioButton label="1">
                <SmallRowTableActive v-if="stateStyle === '1'"/>
                <SmallRowTable v-else/>
              </ElRadioButton>
              <ElRadioButton label="2">
                <BigRowTableActive v-if="stateStyle === '2'"/>
                <BigRowTable v-else/>
              </ElRadioButton>
            </ElRadioGroup>
          </div>
          <div class="flex items-center justify-between w-full pl-1">
            <p class="text-sm text-grey-300 font-semibold text-zebra">{{ $t('archive.zebra') }}</p>
            <el-switch v-model="isZebra"/>
          </div>
        </div>

      </div>

      <div class="archive-options__option" @click="openAddModal">
        <PlusIcon/>
        <span class="text-base m-w curiousBlue">{{ $t('archive.add_state') }}</span>
        <div
          v-click-outside="closeAddModal"
          v-if="isOpenAddModal"
          class="absolute z-10 flex flex-col
          items-center justify-between gap-2 h-[152px]
          w-[289px] bg-white shadow-lg rounded p-2
          border border-lightGrey-300 archive-add-new-state">
          <ElInput v-model="form.name" :placeholder="$t('archive.state_name')"/>
          <ElDatePicker v-model="form.date_from" type="date" @change="handleDateChange" :clearable="false"/>
          <ElButton
            class="bg-blue-200 text-white border-0 w-full"
            @click="addNewState">
            {{ $t('archive.add_button') }}
          </ElButton>
        </div>
      </div>

    </div>

    <StateArchiveTable
      :archiveList="archiveList"
      :statesTable="titles"
      :isZebra="isZebra"
      :rowStyle="rowStyle"
      :stateStyle="stateStyle"
      @sort-change="archiveSortHandler"
      @onEdit="onEdit"
      @onCopyAndSetCurrent="onCopyAndSetCurrent"
      @onDelete="onDelete"
    />
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import { useStore } from '@/store/store'
import BackIcon from '@/components/unitsNew/assets/icons/back'
import PlusIcon from '@/components/unitsNew/assets/icons/plus'
import SmallRowTable from '@/components/unitsNew/assets/icons/smallRowTable'
import SmallRowTableActive from '@/components/unitsNew/assets/icons/smallRowTableActive'
import BigRowTable from '@/components/unitsNew/assets/icons/bigRowTable'
import BigRowTableActive from '@/components/unitsNew/assets/icons/bigRowTableActive'
import StateArchiveTable from '@/components/unitsNew/components/Objects/Sections/Shifts/StateArchiveTable'

import { useI18n } from '@/hooks/useI18n'
import { objectsApi } from "@/api";
import { createVuexHelpers } from 'vue2-helpers'

// import { getCurrentInstance} from 'vue'
// const instance = getCurrentInstance();

const { useMutations } = createVuexHelpers()

const {
  SET_ACTIVE_OBJECT,
  SET_MODAL_TYPE,
} = useMutations('units', [
  'SET_ACTIVE_OBJECT',
  'SET_MODAL_TYPE',
])
const $t = useI18n()

const Store = useStore()

const archiveList = computed(()=>{
  if(search.value){
    return Store.getters['archive/getArchiveList'].filter(archive=>archive.name.toLowerCase().includes(search.value.toLowerCase()))
  }else{
    return Store.getters['archive/getArchiveList']
  }
})
const unit = computed(()=>Store.getters['archive/getUnit'])
const isOpenViewEdit = ref(false)
const isOpenAddModal = ref(false)
const search = ref('')
const stateStyle = ref('1')
const isZebra = ref(false)
const rowStyle = computed(() => {
  return stateStyle.value === '1'? { height:'60px' }: { height:'40px'}
})
const closeViewEdit = () => {
  isOpenViewEdit.value = false
}
const openViewEdit = () => {
  isOpenViewEdit.value = true
}
const closeAddModal = () => {
  isOpenAddModal.value = false
}
const openAddModal = () => {
  isOpenAddModal.value = true
}

const form = ref({
  name: '',
  date_from: ''
})

const setTodayDate = () => {
  const today = new Date();
  const day = String(today.getDate()).padStart(2, '0');
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const year = today.getFullYear();
  form.value.date_from = `${year}-${month}-${day}`
}

const statesTable = ref([
    {
      label: $t('archive.state_name'),
      prop: 'name',
      width: '12',
      headerAlign: 'left',
      align: 'left',
      icon: true,
      orderSort: '',
      class: ['max-w-textLong']
    },
    {
      label: $t('archive.state_protocol'),
      prop: 'state_unit.terminal_type.value',
      width: '5',
      headerAlign: 'left',
      align: 'left',
      icon: true,
      orderSort: '',
      class: ['greyToneThird']
    },
    {
      label: $t('archive.state_date'),
      prop: 'date_from',
      width: '5',
      headerAlign: 'left',
      align: 'left',
      icon: true,
      orderSort: '',
      class: ['greyToneThird', 'max-w-textMiddle']
    },
    {
      label: $t('archive.state_imei'),
      prop: 'state_unit.imei',
      width: '7',
      headerAlign: 'left',
      align: 'left',
      icon: true,
      orderSort: '',
      class: ['greyToneThird', 'max-w-textMiddle']
    },
    {
      label: '',
      prop: 'settings',
      width: '2',
      headerAlign: 'left',
      align: 'left'
    }
])

const archiveSortHandler = (idx) => {
  if (statesTable.value[idx].orderSort === 'desc') {
    statesTable.value[idx].orderSort = 'asc'
  } else if (statesTable.value[idx].orderSort === 'asc') {
    statesTable.value[idx].orderSort = ''
  } else {
    statesTable.value[idx].orderSort = 'desc'
  }
  archiveList.value.sort((a, b) => {
    if (statesTable.value[idx].orderSort === 'asc') {
      return a[statesTable.value[idx].prop] > b[statesTable.value[idx].prop] ? 1 : -1;
    } else if (statesTable.value[idx].orderSort === 'desc') {
      return a[statesTable.value[idx].prop] < b[statesTable.value[idx].prop] ? 1 : -1;
    } else {
      return 0;
    }
  });
};
const titles = ref(statesTable.value)
watch(()=>statesTable.value,()=>{
  titles.value = statesTable.value
},{deep:true})

const handleDateChange = (date) => {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const day = String(d.getDate()).padStart(2, '0');
  form.value.date_from = `${year}-${month}-${day}`
}

const addNewState = async ()=>{
    try {
      const response = await Store.dispatch('archive/addNewArchiveState', {id: unit.value.id, form:form.value})
      if(response) {
        form.value.name = null;
        setTodayDate();
        isOpenAddModal.value = false;
      }
    } catch (error) {
      this.$notify.error({
        duration: 3000,
        title: 'Ошибка',
        message: error.response.data.message
      })
    } finally {
      await updateUnitStates();
    }
}

const onEdit = async (row) => {
  Store.commit('archive/setEditableArchive',row)
  try {
    await objectsApi.getObject(
      row.state_unit.id,
      (response) => {
        const activeObject = JSON.parse(JSON.stringify(response.data))

        SET_ACTIVE_OBJECT(activeObject)
        SET_MODAL_TYPE('edit')

        Store.commit('archive/setOpenEdit',true)
      },
      (error) => {
        console.error('error', error)
        // instance.proxy.$showError(error.response.data.message)
      }
    )
  } catch (e) {
    console.error(e)
  } finally {}

}

const onCopyAndSetCurrent = async (row) => {
   const unitId = row.id
  try {
    await Store.dispatch('archive/copyArchiveState',{id: unitId})
  } catch (e) {
    console.error(e)
  } finally {
    await updateUnitStates();
  }
}
const onDelete = async (row) => {
  const unitId = row.id
  try {
    await Store.dispatch('archive/deleteArchiveState',{ id: unitId })
  } catch (e) {
    console.error(e)
  } finally {
    await updateUnitStates();
  }
}

/**
 * Обновляю список объектов
 * после изминения состояния объекта
 * */
const updateUnitStates = async () => {
  await Store.dispatch('units/GET_UNITS');

  const unitId = unit.value.id;
  await Store.dispatch('archive/getArchiveStates', { id: unitId });
}

onMounted(()=>{
  setTodayDate()
})
</script>
<style lang="scss" scoped>
.archive {
  width: 800px;
  position: absolute;
  left: 72px;
  top: 20px;
  bottom: 20px;
  overflow-y: auto;
  background-color: white;
  border-radius: 16px;
  z-index: 30;

  &__header {
    font-family: 'Source Sans Pro', serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;
  }

  &__header-small {
    font-family: 'Source Sans Pro', serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    color: #A6AEB8;
  }

  .tool-icon:hover {
    circle {
      fill: $light-grey-second-tone-color;
    }
  }
  &-options{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 16px;
    &__option{
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      min-width: 180px;
      height: 23px;
      cursor: pointer;
      span{
        font-size: 14px;
        line-height: 19px;
        font-weight: 600;
      }
    }
  }
  .options_wrapper {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s ease-in-out;
    cursor: pointer;
    &:hover {
      background: #DCE1E7;
    }
  }

  ::v-deep(.el-icon-date:before) {
    position: absolute;
    transform: translate(-7px, -2px);
    color: rgba(41, 41, 41, 0.55);
  }

  ::v-deep(.el-switch.is-checked .el-switch__core) {
    border-color: #3A7CCA!important;
    background-color: #3A7CCA!important;
  }
}

.state-radio-group {
  ::v-deep(.el-radio-button--small .el-radio-button__inner){
    padding: 0;
    overflow: hidden;
  }
}

.table-show-type {
  border: 1px solid #EDEFF3;
  border-radius: 6px;
  padding: 9px;
  width: 200px;
  top: 32px;
}

.archive-add-new-state {
  position: fixed;
  border: 1px solid #EDEFF3;
  top: 144px;
  border-radius: 6px;
  padding: 9px;
}

.text-zebra {
  font-family: 'Source Sans Pro', serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  text-align: left;
}



.archive-add-new-state {
  ::v-deep(.el-input__inner) {
    border-radius: 8px;
    background-color: #EDEFF3;
    color: #292929;
    font-size: 15px;
    font-family: 'Source Sans Pro', serif;
    font-weight: 400;
    line-height: 19px;
    text-align: left;

    &::placeholder {
      color: #A6AEB8;
    }

    &:active {
      border-color: #d1dbe4;
    }
  }

  ::v-deep(.el-button) {
    border-radius: 10px;
    font-family: 'Source Sans Pro', serif;
    font-weight: 500;
    font-size: 16px;

    &:hover {
      background-color: #2767B6;
      color: white;
    }

    &:focus {
      outline: none;
    }
  }
}
.archive {
  ::v-deep(.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell) {
    background: rgba(246, 247, 250, 0.8);
  }

  ::v-deep(.el-table .el-table__body tr.el-table__row td.el-table__cell) {
    border-bottom: 1px solid #EDEFF3;
  }
}

</style>
